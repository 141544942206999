@charset "UTF-8";

//各種パラメータ
@import 'param';

//プロジェクトパラメータ
@import 'object/project/project_variables';

//ミックスイン
@import 'mixin';


.p-data-list {
  margin-top: 40px;
  line-height: 1.8;
  font-size: 15px;
  @include mq(md) {
    margin-top: 30px;
  }

  > &:first-child { margin-top: 0; }
  > &:last-child { margin-bottom: 0; }
  dt {
    font-weight: 700;
    color: $key-color-01;
    margin-bottom: 5px;
    ~ dt {
      margin-top: 20px;
      @include mq(md) {
        margin-top: 15px;
      }
    }
  }
  dd {

  }
}

.p-strong-text {
  margin-top: 40px;
  text-align: center;
  font-size: 1.6rem;
  @include mq(md) {
    margin-top: 30px;
  }
}


.c-mainvisual {
  &__copy {
    @include mq-min {
      position: absolute;
      left: 5%;
      bottom: 250px;
    }
  }
}


.u-hide-pc {
  @include mq(md) {
    display: none;
  }
}
.u-hide-sp {
  @include mq(md) {
    display: none;
  }
}

.u-mb-0 {
  margin-bottom: 0!important;
}
.u-mt-0 {
  margin-top: 0!important;
}